import React from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Icon from '../../styles/atoms/icons';
import {
  BlogConversionPanelHeading,
  BlogConversionPanelStyles,
} from './ConversionPanelStyles';

const BlogConversionPanel = () => (
  <BlogConversionPanelStyles className="blog-conversion-panel">
    <div className="heading-block">
      <Icon id="sm-logo" isImage />
      <BlogConversionPanelHeading>
        Shopmonkey Shop Management Software
      </BlogConversionPanelHeading>
    </div>
    <p className="subhead">
      Shopmonkey is a comprehensive software solution built specifically to help
      shop owners streamline their operations, boost revenue, and substantially
      elevate the trajectory of their business.
    </p>
    <CallToAction
      link="/demo"
      variant="primary"
      size="lg"
      value="Request a Demo"
    />
  </BlogConversionPanelStyles>
);

export default BlogConversionPanel;
